/*
dataTables for Danmarks Jægerforbund

1. Render Functions for Additional Info
2. Default Settings for DataTables
3. Init of DataTable
4. Calender DataTable
5. HjorteVildt DataTable
6. Udvalg DataTable
7. Kredse DataTable
8. Lokalforening DataTable
8. Adminstrator DataTable

*/


var djf = djf || {};

//New code
$.extend(jQuery.fn.dataTableExt.oSort, {
    "date-uk-pre": function (a) {
        var x;
        try {
            var dateA = a.replace(/ /g, '').split("-");
            var day = parseInt(dateA[0], 10);
            var month = parseInt(dateA[1], 10);
            var year = parseInt(dateA[2], 10);
            var date = new Date(year, month - 1, day);
            var getDatetime = date.getTime();
            x = !isNaN(getDatetime) ? getDatetime : 10000000000000000000; // Kinda of hacky but its works
        }
        catch (err) {
            console.log("error" + err);
            x = new Date().getTime();
        }

        return x;
    },

    "date-uk-asc": function (a, b) {
        return a - b;
    },

    "date-uk-desc": function (a, b) {
        return b - a;
    }
});

// 1. Render Functions for Additional Info
function calenderDetails(d) {
    if (!d.details) { return ''; }
    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="0" border="0" class="details-table">'+
        '<tbody class="block">'+
        '<tr class="block">'+
            '<td class="block">'+
                '<div class="details-wrapper clearfix">'+
                    '<div class="content-wrapper col-sm-5">'+
                        '<div class="row">'+
                            (d.details.imgURL ? '<div class="col-xs-6 col-sm-12 detail-image"><img src="'+d.details.imgURL+'"></div>' : '')+
                            '<div class="col-xs-6 col-sm-12 detail-desc"><p>'+d.details.desc+'</p></div>    '+
                        '</div>'+
                    '</div>'+
                    '<div class="contact-wrapper col-sm-7">'+
                        '<table class="contact-info">'+
                            (d.details.time ? '<tr><td class="title">Tid</td><td>'+d.details.time+'</td></tr>' : '')+
                            (d.details.place ? '<tr><td class="title">Sted</td><td>' + d.details.place + '</td></tr>' : '') +
                            (d.details.signupURL.indexOf('flexbillet') == -1 && d.details.price ? '<tr><td class="title">Pris</td><td>' + d.details.price + '</td></tr>' : 
                                (d.details.price ? '<tr><td class="title">Pris (medlem)</td><td>' + d.details.price + '</td></tr>' : '') +
                                (d.details.priceNonMember ? '<tr><td class="title">Pris (ikke-medlem)</td><td>' + d.details.priceNonMember + '</td></tr>' : '')) +
                            (d.details.signup ? '<tr><td class="title">Tilmeldingsfrist</td><td>'+d.details.signup+'</td></tr>' : '')+
/*                            '<tr>'+
                                '<td class="title">Kontaktperson</td>'+
                                '<td>'+
                                    '<p>'+d.details.contact.name+'</p>'+
                                    '<p><a href="mailto:'+d.details.contact.mail+'">'+d.details.contact.mail+'</a></p>'+
                                    '<p>Tlf. '+d.details.contact.phone+'</p>'+
                                '</td>'+
                            '</tr>'+*/
                        '</table>'+
                    '</div>'+
                    '<div class="col-xs-12 button-wrapper clearfix">'+
                        '<a href="'+d.details.readmoreURL+'" target="_blank" class="button green-button white">Læs mere</a>'+
                        (d.details.signupURL && d.details.openForRegistration ? '<a href="' + d.details.signupURL + '" target="_blank" class="button green-button solid">' + (d.details.eventType == 'EVENT' ? 'Tilmeld' : 'Jeg er interesseret') + '</a>' : '') +
                    '</div>'+
                '</div>'+
            '</td>'+
        '</tr>'+
        '</tbody>'+
    '</table>';
}

function localDetails ( d ) {
    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="0" border="0" class="details-table">'+
        '<tbody class="block">'+
        '<tr class="block">'+
            '<td class="block">'+
                '<div class="details-wrapper clearfix">'+
                    '<div class="col-xs-12 scroll-x">'+
                        '<table class="local-info">'+
                            '<thead>'+  
                                '<tr>'+
                                    '<th style="min-width: 140px">Jægerråd</th>'+
                                    '<th>' + (d.details.treasurer && d.details.treasurer.name ? 'Jagtforeningskasserer' : '') + '</th>'+
                                    '<th>' + (d.details.chairman && d.details.chairman.name ? 'Jagtforeningsformand' : '') + '</th>' +
                                '</tr>'+
                            '</thead>'+ 
                            '<tbody>'+  
                                '<tr>'+
                                    '<td style="min-width: 140px">'+d.details.jfk+'</td>'+
                                    '<td>' + (d.details.treasurer && d.details.treasurer.name ? d.details.treasurer.name : '') + '</td>' +
                                    '<td>'+ (d.details.chairman && d.details.chairman.name ? d.details.chairman.name : '') + '</td>'+
                                '</tr>'+
                                '<tr>'+
                                    '<td style="min-width: 140px"></td>'+
                                    '<td>' + (d.details.treasurer && d.details.treasurer.phone1 ? d.details.treasurer.phone1 : '') + '</td>' +
                                    '<td>' + (d.details.chairman && d.details.chairman.phone1 ? d.details.chairman.phone1 : '') + '</td>' +
                                '</tr>'+
                                '<tr>'+
                                    '<td style="min-width: 140px" class="th">Jagtforenings nr.</td>'+
                                    '<td>' + (d.details.treasurer && d.details.treasurer.phone2 ? d.details.treasurer.phone2 : '') + '</td>' +
                                    '<td>' + (d.details.chairman && d.details.chairman.phone2 ? d.details.chairman.phone2 : '') + '</td>' +
                                '</tr>'+
                                '<tr>'+
                                    '<td style="min-width: 140px">'+d.number+'</td>'+
                                    '<td>' + (d.details.treasurer && d.details.treasurer.email ? d.details.treasurer.email : '') + '</td>' +
                                    '<td>' + (d.details.chairman && d.details.chairman.email ? d.details.chairman.email : '') + '</td>' +
                                '</tr>'+
                            '</tbody>'+  
                        '</table>'+
                    '</div>' +
                    (d.details.website ?
                    '<div class="col-xs-12 button-wrapper clearfix">'+
                        '<a href="'+d.details.website+'" class="button green-button" target="_blank">Besøg website</a>'+
                    '</div>' : '') +
                '</div>'+
            '</td>'+
        '</tr>'+
        '</tbody>'+
    '</table>';
}

// 2. Default Settings for DataTables
//$.datepicker.setDefaults($.datepicker.regional["da"]);
$.extend( $.fn.dataTable.defaults, {
    "bLengthChange": false, // Hide lenght menu
     "dom": '<"mobile-filter"<"button-wrapper"<"#open-filter"><"#open-sorter">><"clear-all">><"sorter-Outerwrapper"<"custom-sorter">><"filter-Outerwrapper"<"custom-filter"><"brush-divider"><"clear-all">f><t><"bottom-pagination" irp>',
    //"dom": '<"mobile-filter"><"sorter-Outerwrapper"<"custom-sorter">><"filter-Outerwrapper"<"custom-filter">f><t><"bottom-pagination" irp>',
    "pagingType": "simple", // Only show the prev/next button
    "language": {
        "processing":  "Indlæser..",
        "search":  "",
        "searchPlaceholder": "Søg...",
        "lengthMenu": "Vis _MENU_ per side",
        "emptyTable": "Der blev desværre ikke fundet nogle opslag",
        "zeroRecords": "Der blev desværre ikke fundet nogle opslag",
        "info": "Side _PAGE_ af _PAGES_",
        "infoEmpty": "",
        "paginate": {
          "next": "Næste",
          "previous": "Forrige"
        }
    },
    "infoCallback": function( settings, start, end, max, total, pre ) {
       var api = this.api();
       var pageInfo = api.page.info();
       
       return 'Side '+ (pageInfo.page+1) +' af '+ pageInfo.pages;
     }
} );

// 3. Init of DataTable
$(function () {
 
    $.fn.dataTable.moment = function ( format, locale ) {
        var types = $.fn.dataTable.ext.type;
     
        // Add type detection
        types.detect.unshift( function ( d ) {
            return moment( d, format, locale, true ).isValid() ?
                'moment-'+format :
                null;
        } );
     
        // Add sorting method - use an integer for the sorting
        types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
            return moment( d, format, locale, true ).unix();
        };
    };


    djf.table.init();
    djf.table.initGridTables();
});

djf.table = new function () {
    var self = this,
        tabbar = $('.tab-bar li');


    this.init = function () {
        if( $('.dataTable#all-table').length )
        {
            self.dataTableCalender("all-table", "alle");
        }

        if( $('.dataTable#all-event-table').length )
        {
            self.dataTableEventCalender("all-event-table", "alle");
        }

        // Add readonly to datepicker
        $('.hasDatepicker').attr('readonly','readonly');
    };

    this.initGridTables = function () {

        // Init eventTables
/*        $('.eventTable').each(function() {
            var id = $(this).attr('id'),
                endpoint = $(this).attr('data-url');

            self.dataTableCalender(id, endpoint);
        });*/

        $('.contactpersonTable').each(function() {
            var id = $(this).attr('id'),
                endpoint = $(this).attr('data-url'),
                isRegionContacts = $(this).attr('data-is-region-contacts') === 'true';

            if (isRegionContacts) {
                self.dataTableContactKreds(id, endpoint);
            } else {
                self.dataTableContactUdvalg(id, endpoint);
            }
        });
    }


    this.dataTableDefaults = function (d) {
        var el = $('#'+d+''),
            body = $('body, html'),
            table = el.DataTable(),
            tableWrapper = $('#'+d+'_wrapper'),
            mobileFilter = tableWrapper.find('.mobile-filter'),
            customSorter = tableWrapper.find('.custom-sorter'),
            customFilters = tableWrapper.find('.filter-wrapper'),
            sortFilters = el.find('thead th').not('.sorting_disabled'),
            filterContent = tableWrapper.find('.filter-Outerwrapper').get(),
            sortContent = [],
            filterModal = $('#filterModal'),
            customRegionFilterId = '#'+d+'-custom-region-filter';

        // Get the sort content
        if( sortContent.length == 0 )
        {
            sortFilters.each(function() {
                var filterName = $(this).html();
                $(this).attr('data-filter', filterName);

                sortContent.push('<li class="sort-filter" data-filter='+filterName+'>'+filterName+'</li>');
            });
        }

        // Remove sort-button if no columns has filter
        if (sortFilters.length == 0) {
            tableWrapper.find('#open-sorter').hide();
        }

        // Hide clear-all if custom filters is 0 
        if (customFilters.length == 0) {
            tableWrapper.find('.clear-all').addClass('hidden-desktop');
        }

        // Append overlay triggers to mobileFilter
        mobileFilter.find('#open-filter').html("Filtrer");
        mobileFilter.find('#open-sorter').html("Sorter");
        tableWrapper.find('.clear-all').html('<span>Ryd filter</span><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/icons/icons.svg#close"></use></svg>');

        
        // Remove filter buttons if no-filter
        if( customFilters.length == 0 ) {
            mobileFilter.find('#open-filter, .clear-all').remove();
        }

        // Open sorter
        tableWrapper.find('#open-sorter').on('click', function(event) {
            event.preventDefault();

            filterModal.find('.modal-body').html('<ul class="sort-list">'+sortContent.join('')+'</ul>');
            filterModal.find('.filter-header span').html('Sorter visning');
            filterModal.find('.button-wrapper').hide();
            filterModal.modal('show');
        });

        // Filter the table and close the sorter
        filterModal.on('click','.sort-filter', function() {
            var dataFilter = $(this).attr('data-filter');
            el.find('thead th[data-filter='+dataFilter+']').trigger('click');
            filterModal.modal('hide');
        });

        // Open filter
        tableWrapper.find('#open-filter').on('click', function(event) {
            event.preventDefault();
            filterModal.find('.modal-body').html(filterContent);
            filterModal.find('.filter-header span').html('Filtrer visning');
            filterModal.find('.button-wrapper').show();
            filterModal.modal('show');
        });


        // Close the filter
        filterModal.find('#close-filter').on('click', function(event) {
            event.preventDefault();
            tableWrapper.prepend(filterContent);
            filterModal.modal('hide');
        });

        // Close filters on keyup
        $(document).keyup(function(e) {
            if (e.keyCode == 13 || e.keyCode == 27) {
                $('.filter-Outerwrapper, .sorter-Outerwrapper').removeClass('open');
                body.removeClass('overlay-open');
            } 
        });

        // Clear all filters
        tableWrapper.find('.clear-all').on('click', function(event) {
            event.preventDefault();
            tableWrapper.find('.yadcf-filter-reset-button').click();
            filterModal.find('.yadcf-filter-reset-button').click();
            $(customRegionFilterId).val(0).change();
            table
             .search( '' )
             .columns().search( '' )
             .draw();

        });

    }

    // 4. Calender DataTable
    this.dataTableCalender = function (t, dataSrc, region) {
        var el = $('#'+t+'');
        var api = '/umbraco/api/EventApi/All';
        var hasType = true;
        var organizerWidth = 30;
        var isByTag = (dataSrc != '' && dataSrc != 'alle');
        var region = region || "";
        var newApi;
        var table;

        if (dataSrc != '' && dataSrc != 'alle') {
            api += "By?tag=" + dataSrc;
            hasType = false;
            organizerWidth = 60;
        }

        if (region != '') {
            api += (isByTag ? "&" : "?") + "region=" + region;
        }

        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            table = el.DataTable( {
                "ajax": api,
                "columns": [
                    { "title": "Region", "data": "regionName", "bVisible": false},
                    { "title": "Lokalforening", "data": "local", "bVisible": false},
                    { "title": "Aktivitet", "data": "name", "className": "mobile-full col-sm-3 col-md-5 activity" },
                    { "title": "Arrangør", "data": "organizerName", "className": "mobile-"+organizerWidth+" border" },
                    { "title": "Type", "data": "type", "className": "mobile-30 border", "bVisible": hasType, "orderable": hasType  },
                    { "title": "Dato", "data": "dateStart", "type": "date-uk", "className": "mobile-40 border"},
                    { "title": "Mere info", "orderable": false, "defaultContent": "<button></button>", "data": null, "className": "details-control" }
                ],
/*                "fnDrawCallback": function( o ) {
                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }
                },*/
                "order": [[ 5, "asc" ]]
            } );


            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');
/*
            filterContainer.after('<div class="button-wrapper"><button id="close-filter" class="button green-button solid">Filtrer</button></div>');
            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');*/
            customFilter.prepend('<div id="regionFilter-'+t+'" class="filter-wrapper"></div><div id="localFilter-'+t+'" class="filter-wrapper"></div><div class="brush-divider"></div><div id="dateFilter-'+t+'" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              // {column_number : 0, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "regionFilter-"+t},
              {column_number : 1, filter_type: "select", filter_default_label: "Alle lokalforeninger", filter_container_id: "localFilter-"+t, filter_match_mode: "exact"},
              {column_number : 5, filter_type: "range_date", date_format: "dd-mm-yyyy", filter_default_label: ["Fra", "Til"], filter_container_id: "dateFilter-"+t}
            ]);



            // Add event listener for opening and closing details
            el.find('tbody').on('click', 'td.details-control', function () {
                var tr = $(this).closest('tr');
                var row = table.row( tr );

                if ( row.child.isShown() ) {
                    $(this).removeClass('open');
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    $(this).addClass('open');
                    // Open this row
                    row.child( calenderDetails(row.data()) ).show();
                    tr.addClass('shown');
                }
            } );

            // Onchange function on region filter
            $('body').on('change', '#'+t+'-custom-region-filter', function(event) {
                var selectVal = $(this).children('option:selected').val();
                newApi = api + (isByTag ? "&" : "?") + "region=" + selectVal;

                if (selectVal != 0) {
                    table.ajax.url(newApi).load();
                } else {
                    table.ajax.url(api).load();
                }

                // Reset the local-filter
                $('#yadcf-filter-wrapper-localFilter-'+t+' select').val(-1).change();
                
            });
            
        } else {
            table = table || {};
        }

        // Load all the default stuff
        self.dataTableDefaults(t);
        self.initRegionFilter(t, dataSrc);


    };

    this.initRegionFilter = function(d, dataSrc) {
        var $selectWrapper = $('#regionFilter-'+d),
            selectID = '#'+d+'-custom-region-filter',
            options = "<option value='0'>Vælg kreds</option>";

        if (!$(selectID).length) {

            $selectWrapper.append('<select id="'+selectID.replace('#','')+'" class="yadcf-filter"></select>');

            for (i = 1; i < 9; i++) {
                options += "<option value="+i+">Kreds " + i + "</option>";
            }
            options += "<option value='9000'>HB m.v.</option>";
             
            $(selectID).prepend(options);
        }

    }


    // 4. Calender DataTable
    this.dataTableEventCalender = function (t, dataSrc, region) {
        var el = $('#' + t + '');

        if (!region) {
            region = el.data('regionid');
        }

        var api = '/umbraco/api/EventApi/All';
        var hasType = true;
        var organizerWidth = 30;

        var isByTag = (dataSrc != '' && dataSrc != 'alle');

        if (isByTag) {
            api += "By?tag=" + dataSrc;
            hasType = false;
            organizerWidth = 60;
        }

        if (region != '') {
            api += (isByTag ? "&" : "?") + "region=" + region;
        }
        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": api,
                "columns": [
                    { "title": "Region", "data": "regionName", "bVisible": false},
                    { "title": "Lokalforening", "data": "local", "bVisible": false},
                    { "title": "Aktivitet", "data": "name", "className": "mobile-full col-sm-3 col-md-5 activity" },
                    { "title": "Arrangør", "data": "organizerName", "className": "mobile-"+organizerWidth+" border" },
                    { "title": "Type", "data": "type", "className": "mobile-30 border", "bVisible": hasType, "orderable": hasType },
                    { "title": "Dato", "data": "dateStart", "type": "date-uk", "className": "mobile-40 border"},
                    { "title": "Mere info", "orderable": false, "defaultContent": "<button></button>", "data": null, "className": "details-control" }
                ],
/*                "fnDrawCallback": function( o ) {
                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }
                },*/
                "order": [[ 5, "asc" ]]
            } );


            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');
/*
            filterContainer.after('<div class="button-wrapper"><button id="close-filter" class="button green-button solid">Filtrer</button></div>');
            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');*/
            customFilter.prepend('<div class="brush-divider"></div><div id="dateFilter-'+t+'" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              {column_number : 5, filter_type: "range_date", date_format: "dd-mm-yyyy", filter_default_label: ["Fra", "Til"], filter_container_id: "dateFilter-"+t}
            ]);

            // Add event listener for opening and closing details
            el.find('tbody').on('click', 'td.details-control', function () {
                var tr = $(this).closest('tr');
                var row = table.row( tr );

                if ( row.child.isShown() ) {
                    $(this).removeClass('open');
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    $(this).addClass('open');
                    // Open this row
                    row.child( calenderDetails(row.data()) ).show();
                    tr.addClass('shown');
                }
            } );
        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 5. HjorteVildt DataTable
    this.dataTableHjorteVildt = function (t, dataSrc) {
        var el = $('#'+t+''),
            tableWrapper = $('#'+t+'_wrapper');


        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": '/data/'+dataSrc+'.txt',
                "columns": [
                    { "title": "Område", "data": "area", "className": "mobile-full area"},
                    { "title": "Navn", "data": "name", "className": "mobile-50" },
                    { "title": "Postnr", "data": "postal", "className": "mobile-25" },
                    { "title": "Kreds", "data": "regionName", "className": "mobile-25" },
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false},
                    { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false}
                ],
/*                "fnDrawCallback": function( o ) {
                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }
                },*/
                "order": [[ 3, "desc" ]] // Pre sort on date
            } );
            
            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');
        }
         
        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 6. Udvalg DataTable
    this.dataTableUdvalg = function (t, dataSrc) {
        var el = $('#'+t+'');

        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var tableElement = el.dataTable( {
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "columns": [
                    { "title": "Udvalg", "data": "committee", "className": "mobile-full", "bVisible": false },
                    { "title": "sortorder", "data": "sortorder", "className": "mobile-50", "bVisible": false },
                    { "title": "Titel", "data": "titel", "className": "mobile-full" },
                    { "title": "Navn", "data": "name", "className": "mobile-50" },
                    { "title": "Postnr", "data": "postal", "className": "mobile-25" },
                    { "title": "By", "data": "town", "className": "mobile-25"},
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false},
                    { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false}
                ],
/*                "fnDrawCallback": function( o ) {
                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }
                },*/
                "order": [[ 3, "asc" ]]
            } );

            var table = tableElement.api();

            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');
/*
            filterContainer.after('<div class="button-wrapper"><button id="close-filter" class="button green-button solid">Filtrer</button></div>');
            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');*/
            customFilter.prepend('<div id="committeeFilter-'+t+'" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              {column_number : 0, filter_type: "select", filter_default_label: "Alle udvalg", filter_container_id: "committeeFilter-"+t}
            ]);


            // Change sorting based filtering 
            // Sort by name if all committees is chosen (default). Sort by sortorder if one committee is chosen
            var currentVal = '-1';
            var committeeFilterSelect = $('select', '#committeeFilter-' + t);
            function onSearch() {
                var newVal = committeeFilterSelect.val();
                if (newVal == currentVal) { return; }

                currentVal = newVal;

                if (newVal == '-1') {
                    tableElement.fnSort([[3, 'asc']]);
                } else {
                    tableElement.fnSort([[1, 'asc']]);
                }
            }
            tableElement.on('search.dt', function () { onSearch(); });
        }

/*        // Clear all filters
        $('#clear-all').on('click', function() {
            $('.yadcf-filter-reset-button').click();
            table
             .search( '' )
             .columns().search( '' )
             .draw();
        });*/
         
        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 7. Kredse DataTable
    this.dataTableKredse = function (t, dataSrc) {
        var el = $('#'+t+'');

        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "columns": [
                    { "title": "Kommune", "data": "municipality", "bVisible": false},
                    { "title": "Kreds", "data": "area", "className": "mobile-40 area" },
                    { "title": "Titel", "data": "titel", "className": "mobile-60" },
                    { "title": "Navn", "data": "name", "className": "mobile-40" },
                    { "title": "Postnr", "data": "postal", "className": "mobile-30"},
                    { "title": "By", "data": "town", "className": "mobile-30"},
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false},
                    { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false}
                ],
/*                "fnDrawCallback": function( o ) {
                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }
                },*/
                "order": [[ 3, "asc" ]] // Pre sort on date
            } );

            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');

/*            filterContainer.after('<div class="button-wrapper"><button id="close-filter" class="button green-button solid">Filtrer</button></div>');
            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');*/
            customFilter.prepend('<div id="areaFilter-'+t+'" class="filter-wrapper"></div><div id="titelFilter-'+t+'" class="filter-wrapper"></div><div id="townFilter-'+t+'" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              {column_number : 1, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "areaFilter-"+t},
              {column_number : 2, filter_type: "select", filter_default_label: "Alle titler", filter_container_id: "titelFilter-"+t},
              /*{column_number : 0, filter_type: "select", filter_default_label: "Alle kommuner", filter_container_id: "municipalityFilter-"+t},*/
              {column_number : 5, filter_type: "select", filter_default_label: "Alle byer", filter_container_id: "townFilter-"+t}
            ]);


        }
         
        // Load all the default stuff
        self.dataTableDefaults(t);

    };
    // 8. Lokalforening DataTable
    this.dataTableLokal= function (t, dataSrc) {
        var el = $('#'+t+'');

        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "columns": [
                    { "title": "Kreds", "data": "area", "className": "mobile-20 area border" },
                    { "title": "Forening", "data": "name", "className": "mobile-40 border" },
                    { "title": "Kommune", "data": "municipality", "className": "mobile-40 border" },
                    { "title": "Mere info", "orderable": false, "defaultContent": "<button></button>", "data": null, "className": "details-control" }
                ],
                "order": [[ 1, "asc" ]]
            } );

            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="areaFilter-'+t+'" class="filter-wrapper"></div><div id="municipalityFilter-'+t+'" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              {column_number : 0, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "areaFilter-"+t},
              {column_number : 2, filter_type: "select", filter_default_label: "Alle kommuner", filter_container_id: "municipalityFilter-"+t}
            ]);

            // Add event listener for opening and closing details
            el.find('tbody').on('click', 'td.details-control', function () {
                var tr = $(this).closest('tr');
                var row = table.row( tr );
            
                if ( row.child.isShown() ) {
                    $(this).removeClass('open')
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    $(this).addClass('open')
                    // Open this row
                    row.child( localDetails(row.data()) ).show();
                    tr.addClass('shown');
                }
            } );
        }
         
        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 9. Admin DataTable 
    this.dataTableAdmin = function (t, dataSrc) {
        var el = $('#'+t+''),
            tableWrapper = $('#'+t+'_wrapper');


        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": '/umbraco/Api/ContactApi/GetContacts?memberGroup=' + dataSrc,
                "pageLength": 8,
                "columns": [
                    { "title": "Afdeling", "data": "section", "bVisible": false, "orderable": false },
                    { "title": "Billede", "data": "image", "orderable": false },
                    { "title": "Navn", "data": "name", "bVisible": false }
                ],
                "columnDefs": [
                    {
                      "render": function ( data, type, row ) {
                        var image = "",
                            position = "",
                            name = "",
                            phone = "",
                            email = "",
                            linkedin = "",
                            workFunction = "";

                        // Check if the values are empty
                        if(row.image) {
                            image = '<figure class="img"><img src="'+row.image+'&height=210&width=180&bgcolor=fff&mode=crop"></figure>';
                        }
                        if(row.position) {
                            position = '<div class="position"><p>'+row.position+'</p></div>';
                        }
                        if(row.name) {
                            name = '<div class="name"><p>'+row.name+'</p></div>';
                        }
                        if(row.phone) {
                            phone = '<div class="phone"><p>Tlf: '+row.phone+'</p></div>';
                        }
                        if(row.email) {
                            email = '<div class="email"><a href="mailto:'+row.email+'">'+row.email+'</a></div>';
                        }
                        if(row.linkedin) {
                            linkedin = '<div class="linkedin"><a href="'+row.linkedin+'" target="_blank"><i class="icon-linkedin"></i></a></div>';
                        }
                        if(row.function) {
                            workFunction = '<div class="title">Funktion</div><div class="function"><p>'+row.function+'</p></div>';
                        }


                        return  '<div class="row row-content">'+
                                    '<div class="col col-xs-4 col-sm-3">'+
                                        image+
                                    '</div>'+
                                    '<div class="col col-xs-8 col-sm-9">'+
                                        '<div class="row">'+
                                            '<div class="col col-xs-12 col-sm-6">'+
                                                position+
                                                name+
                                                phone+
                                                email+
                                                linkedin+
                                            '</div>'+
                                            '<div class="col col-xs-12 col-sm-6">'+
                                                workFunction+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'+
                                    '</div>'+
                                '</div>';

                      },
                      "targets": 1
                    }
                ],
                "fnDrawCallback": function( o ) {
/*                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }*/
                    el.find('tr.even').after('<div class="clear"></div>');
                },
                "order": [[ 2, "asc" ]]
            } );
    
            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');

/*            filterContainer.after('<div class="button-wrapper"><button id="close-filter" class="button green-button solid">Filtrer</button></div>');
            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');*/
            customFilter.prepend('<div id="sectionFilter-'+t+'" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              {column_number : 0, filter_type: "select", filter_default_label: "Alle afdelinger", filter_container_id: "sectionFilter-"+t}
            ]);

        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 10. Hovedbestyrelse DataTable 
    this.dataTableHovedBestyrelse = function (t, dataSrc) {
        var el = $('#'+t+''),
            tableWrapper = $('#'+t+'_wrapper');


        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": '/umbraco/Api/ContactApi/GetContacts?memberGroup=' + dataSrc,
                "pageLength": 8,
                "bSort" : false,
                "columns": [
                    { "title": "Billede", "data": "image", "orderable": false },
                    { "title": "kreds", "data": "area", "bVisible": false },
                    { "title": "Navn", "data": "name", "bVisible": false }
                ],
                "columnDefs": [
                    {
                      "render": function ( data, type, row ) {
                        var image = "",
                            position = "",
                            name = "",
                            phone = "",
                            email = "",
                            linkedin = "",
                            workFunction = "";

                        // Check if the values are empty
                        if(row.image) {
                            image = '<figure class="img"><img src="'+row.image+'&height=210&width=180&bgcolor=fff&mode=crop"></figure>';
                        }
                        if(row.position) {
                            position = '<div class="position"><p>'+row.position+'</p></div>';
                        }
                        if(row.name) {
                            name = '<div class="name"><p>'+row.name+'</p></div>';
                        }
                        if(row.phone) {
                            phone = '<div class="phone"><p>Tlf: '+row.phone+'</p></div>';
                        }
                        if(row.email) {
                            email = '<div class="email"><a href="mailto:'+row.email+'">'+row.email+'</a></div>';
                        }
                        if(row.linkedin) {
                            linkedin = '<div class="linkedin"><a href="'+row.linkedin+'" target="_blank"><i class="icon-linkedin"></i></a></div>';
                        }
                        if(row.function) {
                            workFunction = '<div class="title">Funktion</div><div class="function"><p>'+row.function+'</p></div>';
                        }


                        return  '<div class="row row-content">'+
                                    '<div class="col col-xs-4 col-sm-3">'+
                                        image+
                                    '</div>'+
                                    '<div class="col col-xs-8 col-sm-9">'+
                                        '<div class="row">'+
                                            '<div class="col col-xs-12 col-sm-6">'+
                                                position+
                                                name+
                                                phone+
                                                linkedin+
                                                email+
                                            '</div>'+
                                            '<div class="col col-xs-12 col-sm-6">'+
                                                workFunction+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'+
                                    '</div>'+
                                '</div>';

                      },
                      "targets": 0
                    }
                ],
                "fnDrawCallback": function( o ) {
/*                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }*/
                    el.find('tr.even').after('<div class="clear"></div>');
                },
                "order": [[2, "asc"]]
            } );

            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');

/*            filterContainer.after('<div class="button-wrapper"><button id="close-filter" class="button green-button solid">Filtrer</button></div>');
            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');*/
            
            customFilter.prepend('<div id="areaFilter-' + t + '" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              { column_number: 1, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "areaFilter-" + t }
            ]);
        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

        // 6. Udvalg DataTable
    this.dataTableGrupper = function (t, dataSrc) {
        var el = $('#'+t+'');

        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "columns": [
                    { "title": "Gruppe", "data": "committee", "className": "mobile-50" },
                    { "title": "sortorder", "data": "sortorder", "className": "mobile-50", "bVisible": false },
                    { "title": "Navn", "data": "name", "className": "mobile-50" },
                    { "title": "By", "data": "town", "className": "mobile-75"},
                    { "title": "Postnr", "data": "postal", "className": "mobile-25" },
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false},
                    { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false}
                ],
/*                "fnDrawCallback": function( o ) {
                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }
                },*/
                "order": [[ 2, "asc" ]]
            } );

            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="groupFilter-'+t+'" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              { column_number: 0, filter_type: "select", filter_default_label: "Alle grupper", filter_container_id: "groupFilter-" + t }
            ]);
        }
         
        // Load all the default stuff
        self.dataTableDefaults(t);

    };


    this.dataTableJaegerraad = function (t, dataSrc) {
        var el = $('#'+t+'');

        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "columns": [
                    { "title": "Kommune", "data": "municipality", "className": "mobile-50"},
                    { "title": "Navn", "data": "name", "className": "mobile-50" },
                    { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false},
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false}
                ],
                "order": [[ 0, "asc" ]]
            } );

            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="municipalityFilter-'+t+'" class="filter-wrapper"></div>');
            
            yadcf.init(table, [
              { column_number: 0, filter_type: "select", filter_default_label: "Alle kommuner", filter_container_id: "municipalityFilter-" + t }
            ]);
        }
         
        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    this.dataTableContactUdvalg = function (t, dataSrc) {
        var el = $('#'+t+'');
        // Init the table if it is not already
        if( el.find('tr').length == 0) {

            var table = el.DataTable( {
                "ajax": '/umbraco/Api/ContactApi/GetContactsUdvalg?roleName=' + encodeURIComponent(dataSrc),
                "columns": [
                    { "title": "Kreds", "data": "area", "className": "mobile-50 area" },
                    { "title": "Navn", "data": "name", "className": "mobile-50" },
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border"},
                    { "title": "Mail", "data": "email", "className": "mobile-60 border"}
                ],
/*                "fnDrawCallback": function( o ) {
                    var oldStart = 0;
                    if ( o._iDisplayStart != oldStart ) {
                        var targetOffset = el.offset().top;
                        $('html,body').animate({scrollTop: targetOffset}, 250);
                        oldStart = o._iDisplayStart;
                    }
                },*/
                "order": [ 1, "asc" ]
            } );

            // Custom filters
            var filterContainer = $('#'+t+'_filter'); 
            var customFilter = filterContainer.siblings('.custom-filter');

/*            filterContainer.after('<div class="button-wrapper"><button id="close-filter" class="button green-button solid">Filtrer</button></div>');
            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');*/

        }
         
        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    this.dataTableContactKreds = function (t, dataSrc) {
        var el = $('#' + t + '');
        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContactsKreds?regionNumber=' + encodeURIComponent(dataSrc),
                "columns": [
/*                    { "title": "Kommune", "data": "municipality", "bVisible": false },*/
                    //{ "title": "Kreds", "data": "area", "className": "mobile-40" },
                    { "title": "Titel", "data": "titel", "className": "mobile-60" },
                    { "title": "Navn", "data": "name", "className": "mobile-40" },
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border"},
                    { "title": "Mail", "data": "email", "className": "mobile-60 border"}
                ],
                /*                "fnDrawCallback": function( o ) {
                                    var oldStart = 0;
                                    if ( o._iDisplayStart != oldStart ) {
                                        var targetOffset = el.offset().top;
                                        $('html,body').animate({scrollTop: targetOffset}, 250);
                                        oldStart = o._iDisplayStart;
                                    }
                                },*/
                "order": [2, "asc"]
            });

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            /*            filterContainer.after('<div class="button-wrapper"><button id="close-filter" class="button green-button solid">Filtrer</button></div>');
                        customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');*/

        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

};
