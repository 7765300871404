var djf = djf || {};

$(function () {
    djf.accordion.init();
});

djf.accordion = new function () {

	this.init = function () {
		$('.accordion-content:not(.active .accordion-content)').hide();

		$('.accordion-header', '.accordion').on('click', function (e) {
			e.preventDefault();
			var self = $(this).parents("li"),
			selfContent = self.find('.accordion-content'),
			accordionContents = $('.accordion-item').find('.accordion-content');

			if (self.hasClass("active"))
			{
				self.removeClass("active");
				selfContent.slideUp(300);
			}
			else {
				if(!self.hasClass('multi-open')){
					$('.accordion-item').removeClass("active");
					accordionContents.slideUp(300);
				}
				self.addClass("active");
				selfContent.slideDown(300);
			}
		});
	}
};



