var djf = djf || {};

// DocReady
$(function() {

    djf.stageSlider.init();

}); // DocReady


djf.stageSlider = new function () {
    var self = this;

    // Initialize search
    this.init = function() {
        self.flexslider('.slider');
    };

    this.flexslider = function(selector) {
        $(selector).flexslider({
            selector: ".slides > .slide",
            animation: "slide",
            animationLoop: true,
            slideshow: true,
            namespace: "slider-",
            pauseOnAction: false,
            pauseOnHover: true,
            useCSS: true,
            touch: true,
            directionNav: false,
            controlNav: true,
            controlsContainer: ".slider-indicators",
        });
    };
}(); // europcar.stageSlider