/*
calendars for Danmarks Jægerforbund

1. Default Settings for Calendars
2. Init calendar
3. Skydetider calendar
4. Bålhytte/Shelter calendar

*/


var djf = djf || {};

// 2. Init calendar
$(function () {
    if ($('#shooting-calendar').length) {
        djf.shootingCalendar.init();
    }
    if ($('#shelter-calendar').length) {
        djf.shelterCalendar.init();
    }
    
});

// 3. Skydetider calendar / Shooting calendar
djf.shootingCalendar = new function () {

    var hideFromEmail = 'hidefromemail';
    var self = this,
        calendarDiv,
        shootingTimesDiv,
        calendarDate,
        eventSelectedDiv,
        shootingIdInput,
        timeStringInput,
        emailToInput,
        priceInput,
        validationErrors,
        shootingModal,
        selectedDate,
        orderNumberInput,
        submitButton;


    this.selectEvent = function (id) {
        $.ajax('/umbraco/Api/ShootingTimesApi/GetShooting?shootingId=' + id).done(function(data) {
            calendarDate.val('');

            if (data == null) {
                return;
            }

            var date = data.date;
            selectedDate = moment(date).format('DD/MM/YYYY');
            var price = data.price + ' DKK';
            $('#shooting-event').html('<b>Event: ' + data.place + ' - ' + selectedDate + ' </b>' +
                '<br />Instruktør: ' + data.instructor +
                '<br />Pris: ' + price +
                '<br />Bemærkninger: ' + data.notes);

            shootingTimesDiv.empty().append(new Option('Vælg et tidspunkt', '', true, true));
            $.each(data.shootingTimes, function (index, item) {
                shootingTimesDiv.append(new Option(item, item));
            });
            eventSelectedDiv.show();

            shootingIdInput.val(data.id);
            priceInput.val(price);

            emailToInput.val(data.emailTo);

            shootingModal.modal();
        });
    }
    

    this.init = function () {
        calendarDiv = $('#shooting-calendar');
        shootingTimesDiv = $('#shooting-times');
        calendarDate = $('.dato input');
        eventSelectedDiv = $('#event-selected');
        shootingIdInput = $('.shootingid' + hideFromEmail + ' input');
        timeStringInput = $('.timestring' + hideFromEmail + ' input');
        emailToInput = $('.emailto' + hideFromEmail + ' input');
        orderNumberInput = $('.ordrenummer input');
        priceInput = $('.pris input');
        validationErrors = $('.validation-summary-errors');
        selectedDate = '';
        shootingModal = $('#shooting-modal');
        submitButton = $('input[type=submit]');

        // Get modal back if we have validation errors
        if (validationErrors.length) {
            if (shootingIdInput.val().length) {
                self.selectEvent(shootingIdInput.val());
            }
        }

        // Get modal back on submit
        if ($('.contourMessageOnSubmit').length) {
            shootingModal.modal();
            shootingModal.on('hidden.bs.modal', function () {
                window.location = window.location.href;
            });
        }

        if (calendarDiv.length/* && !$('.contourMessageOnSubmit').length*/) {
            calendarDiv.fullCalendar({
                events: {
                    url: '/umbraco/Api/ShootingTimesApi/GetBookableShootingDates',
                    type: 'GET',
                    className: ['shooting-event']
                },

                eventClick: function (calEvent, jsEvent, view) {
                    self.selectEvent(calEvent.id);
                },

                weekNumberCalculation: "ISO",
                weekNumbers: true
            });


            shootingTimesDiv.on('change', function (c) {
                var val = $(this).val();
                calendarDate.val(selectedDate + ' - ' + val);

                timeStringInput.val(val);
            });

            orderNumberInput.val(new Date().getTime());
        }
    };
};

// 3. Bålhytte/Shelter calendar
djf.shelterCalendar = new function () {

    var hideFromEmail = 'hidefromemail';
    var self = this,
        calendarDiv,
        calendarDate,
        eventSelectedDiv,
        validationErrors,
        modal,
        selectedDate,
        shelterTimeModal,
        addShelterTimeButton,
        numPersonsInput,
        selectedEventsInput,
        selectedEventsPrettyInput,
        selectedEvent,
        selectedEvents,
        continueButton;

    // Shelter "event" model
    var Event = (function () {
        function Event(title, startDate, numPersons, element) {
            this.title = title;
            this.startDate = startDate;
            this.numPersons = numPersons;
            this.element = element;
        }
        return Event;
    })();

    function isInt(n) {
        return Number(n) == n && n % 1 === 0;
    }

    this.selectEvent = function (calEvent, calElement) {
        selectedEvent = new Event(calEvent.title, calEvent.start.toDate(), 0, calElement);
        shelterTimeModal.modal();
        numPersonsInput.focus();
    }

    this.deselectEvent = function (calEvent, calElement) {
        for (var i = 0; i < selectedEvents.length; i++) {
            var event = selectedEvents[i];
            if (event.element.get(0) == calElement.get(0)) {
                event.element.find('.fc-title').text(event.title);
                calElement.removeClass('selected');
                selectedEvents.splice(i, 1);
                break;
            }
        }
        self.sync();
    }

    this.sync = function() {
        //selectedEventsDiv.html('');
        for (var i = 0; i < selectedEvents.length; i++) {
            var event = selectedEvents[i];
            //selectedEventsDiv.html(selectedEventsInput.html() + event.startDate.toLocaleString() + ' (' + event.title + '): ' + event.numPersons + ' personer<br />');
            event.element.find('.fc-title').text(event.title + ' (' + event.numPersons + ')');
            event.element.addClass('selected');
        }

        // Set form input value. We don't need the jQuery element here
        selectedEventsInput.val(JSON.stringify(selectedEvents.map(function(ev) {
            return new Event(ev.title, ev.startDate, ev.numPersons, null); 
        })));

        selectedEventsPrettyInput.val(selectedEvents.map(function(ev) {
            return ev.startDate.toLocaleString() + ' (' + ev.title + '): ' + ev.numPersons + ' personer\n';
        }));

        if (selectedEvents.length) {
            $('#next').show();
        } else {
            $('#next').hide();
        }
        
    }

    this.addShelterTime = function () {
        shelterTimeModal.modal('hide');
        var val = numPersonsInput.val();
        var numPersons = 0;
        if (val != null && isInt(val) && val > 0) {
            numPersons = val;
        }
        if (numPersons == 0) {
            return;
        }
        selectedEvent.numPersons = numPersons;
        selectedEvents.push(selectedEvent);

        self.sync();

        numPersonsInput.val('');
        selectedEvent = null;
    }

    this.init = function () {
        calendarDiv = $('#shelter-calendar');
        calendarDate = $('.calendardate input');
        eventSelectedDiv = $('#event-selected');
        validationErrors = $('.validation-summary-errors');
        selectedDate = '';
        modal = $('#shelter-modal');
        shelterTimeModal = $('#shelter-time-modal');
        addShelterTimeButton = $('#add-shelter-time');
        numPersonsInput = $('#numPersons');
        selectedEventsInput = $('.selectedevents' + hideFromEmail + ' input');
        selectedEventsPrettyInput = $('.tiderogpersoner input');
        selectedEvents = [],
        continueButton = $('#next');

        // Get modal back on submit
        if ($('.contourMessageOnSubmit').length) {
            modal.modal();
            modal.on('hidden.bs.modal', function () {
                window.location = window.location.href;
            });
        }

        if (calendarDiv.length) {
            calendarDiv.fullCalendar({
                events: {
                    url: '/umbraco/Api/ShelterApi/GetBookableShelterDates',
                    type: 'GET',
                    className: ['shooting-event']
                },

                eventClick: function (calEvent, jsEvent, view) {
                    var el = $(this);
                    if (el.hasClass('selected')) {
                        self.deselectEvent(calEvent, el);
                    } else {
                        self.selectEvent(calEvent, el);
                    }
                },

                weekNumberCalculation: "ISO",
                weekNumbers: true,
                displayEventTime: true,
                displayEventEnd: true
            });

            addShelterTimeButton.on('click', self.addShelterTime);
            numPersonsInput.on('keyup', function (e) {
                var code = e.which;
                if (code == 13) { // 'Enter'-key
                    self.addShelterTime();
                } 
            });
            continueButton.on('click', function () {
                if (selectedEvents.length) {
                    modal.modal();
                }
            });
        }
    };
};