
var djf = djf || {};

$(function () {
    djf.datepicker.init();
});


djf.datepicker = new function () {

    var self = this;


    this.init = function () {
        $('.datetimepicker').datetimepicker({
            locale: 'da'
        });


        $('.datepicker').datetimepicker({
            locale: 'da',
            format: 'YYYY-MM-DD'
        });
    };

};

