var djf = djf || {};


$(function () {
    djf.tabbar.init();
});

djf.tabbar = new function () {
    var self = this,
        barItems = $('.tab-bar a'),
        scrollBar = $('.tab-bar');


    this.init = function () {
        // Add active menu functionality to tabbar items
        self.tabbarShowTab(barItems);

        self.tabSlider(scrollBar);

    };

    // Move the tab-bar
    this.tabbarScroll = function (t) {
/*        var $this = t,
            wrapper = $this.parents('.tab-bar--wrapper');
            parentCenter = wrapper.outerWidth(true) / 2, // Get center of the wrapper
            tab = $this.children('li'),  // Find the active items
            tabCount = tab.length,
            tabWidth = tab.outerWidth(true), // Get the outer width including padding and margin
            activeTab = $this.find('li.active'),  // Find the active items
            tabsBefore = activeTab.prevAll().length, // Find number of items before the active
            margin = tabWidth * tabsBefore - parentCenter + (tabWidth/2); // Center the active


            if ((tabWidth*tabCount) > wrapper.width()) {

                if (margin > 0) {
                    t.animate({'margin-left': '-'+margin+'px'});
                    //t.css('margin-left', '-'+margin+'px');
                } else {
                    t.animate({'margin-left': '0px'});
                    //t.css('margin-left', '0px');
                };

            }*/

    };

    this.tabSlider = function(selector) {
        selector.addClass('owl-carousel');

        selector.owlCarousel({
            stagePadding: 20,
            loop:false,
            center:false,
            margin:0,
            nav:true,
            navText:["",""],
            margin: 10,
            autoWidth: false,
            URLhashListener:true,
            startPosition: 'URLHash',
            dotsEach: true,
            onInitialize: onInit,
            responsive:{
                0:{
                    stagePadding: 20,
                    items:2
                },
                600:{
                    items:3
                },
                768:{
                    items:3
                },
                1024:{
                    items:6,
                    mouseDrag:false
                },
                1300:{
                    items:8,
                    mouseDrag:false
                }
            }
        })

        
        function onInit() {
           var selectorItem = $('a[data-hash]');
           var hashID = window.location.hash.replace("#", "");
           
            if (hashID!="" && selectorItem.length) {
                $('a[data-hash='+hashID+']').click();
            }
        }

    };



    // Toggle active class
    this.tabbarShowTab = function (selector) {

            selector.on('click', function() {

                self.initActiveTab($(this), selector)

            });

    };

    this.initActiveTab = function (selector, selectorClass) {

        selector.parents('.tab-bar').find(selectorClass.not(selector).removeClass('active'));
        selector.addClass('active');

        var tabId = selector.attr('data-tab');
        var tabContent = selector.parents('.tab-bar--outer').find('.tab-content');

        // Check if the tab-content match with the clicked list-items
        $(tabContent).each(function() {
            if ($(this).attr('data-tab') == tabId){
                $(this).show().addClass('active');
            }else{
                $(this).hide().removeClass('active');
            }
        });


    };

};
