var djf = djf || {};

$(function () {
    djf.login.init();
});


djf.login = new function () {
    var self = this,
        modalTrigger = $('a.modal-trigger');


    this.init = function () {
        self.popup(modalTrigger);
    };
    this.popup = function (t) {
        t.click(function(e){
            e.preventDefault();
            var $button = $(this),
            modal = $('#modal'),
            modalContent = $button.attr('href');

            // Close the menu
            $('html').removeClass('menu-open');

            $.get(modalContent, function (data) {
                 modal.find('.modal-content .modal-body').html(data);
            });

            // Fire modal
            modal.modal();
        });

    };

};
