var djf = djf || {};

$(function () {
    djf.page.init();
});


djf.page = new function () {
    var self = this,
        page = $('#page'),
        responsiveVids = $('.fitvids'),
        shareWrapper = $('.share');


    this.init = function () {
        // Bind the menu-button on init.
        // self.equalPage(page);
        self.fitVids(responsiveVids);
        self.hideshare(shareWrapper);
        self.initAds();
        self.defaults();

    };
    this.equalPage = function (t) {
        var $this = $(this),
            $sidebar = $('.page-sidebar'),
            $maincontent = $('.page-content');

        if ($sidebar.length > 0) {
            $sidebar.css('min-height', $maincontent.outerHeight(true)+'px');
        }
    };

    this.fitVids = function (t) {
         $(t).fitVids();
    }

    this.hideshare = function (t) {
        $(t).hideshare({
/*          link: "",           // Link to URL defaults to document.URL
          title: "",          // Title for social post defaults to document.title
          media: "",          // Link to image file defaults to null*/
          decription: "",     // Description for Facebook summary
          facebook: true,     // Turns on Facebook sharing
          twitter: true,      // Turns on Twitter sharing
          pinterest: true,    // Turns on Pinterest sharing
          googleplus: true,   // Turns on Google Plus sharing
          linkedin: true,     // Turns on LinkedIn sharing
          position: "left", // Options: Top, Bottom, Left, Right
          speed: 50           // Speed of transition
        });
    }

    this.initAds = function () {
        var adText = $('.ad-text'),
            adContainer = adText.closest('div');

        adText.html('Annonce');
        adContainer.css('position', 'relative');
    }

    this.defaults = function () {
        $('body').bind('focusin focus', function(e) {
            e.preventDefault();
        });

        $('.focus :input:first').focus();
    }

};