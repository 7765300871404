var djf = djf || {};

$(function () {
    djf.search.init();
});


djf.search = new function () {
    var self = this,
    	form = $('.nav-search-form');
    	submitButton = form.find('button.submit');


    this.init = function () {

        if ($(window).width() > 960) {
           self.triggerInput(submitButton);
        };
    };
    this.triggerInput = function (t) {

        // Prevent close with click on input
        form.find('input[type="text"]').click(function(e) { //button click class name is myDiv
          e.stopPropagation();
        })

        // Toggle form-open if class does not exist
    	t.on('click', function (e) {
    		var $this = $(this);
    		if (!$this.closest(form).hasClass("form-open")) {
                e.stopPropagation();
	    	    e.preventDefault();
	    	    // Toggle body class
	    	    $this.closest(form).addClass("form-open");
                $('#search').focus();
    		};
    	});

        // Close form
        $(document).click(function(){
            if (form.hasClass("form-open")) {
                form.removeClass("form-open"); //hide the button
            };
         });

    };

};