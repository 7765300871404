
var djf = djf || {};

$(function () {
    djf.shootinglanes.init();
});


djf.shootinglanes = new function () {
    var self = this,
        $moduleForm = $('.shooting-lanes').find('form'),
        $selects = $moduleForm.find('select');


    this.init = function () {

        self.triggerForm($selects);
        self.clearForm($moduleForm);
    };

    this.triggerForm = function (selector) {

    	selector.on('change', function() {
    		$moduleForm.submit();
    	});

    };

    this.clearForm = function (selector) {
    	var $clearTrigger = selector.find('.clear-all'),
    		$inputs = selector.find('input'),
    		$selects = selector.find('select'),
            url = window.location.href.split('?')[0];

    	$clearTrigger.click(function() {
    		
    		// Clear inputs
/*    		$inputs.val('');

    		// Clear selects
    		$selects.each(function() {
    			var showAllOption = $(this).find('option[value="0"]');
    			showAllOption.prop('selected', true);
    		});

    		// Submit the form
    		selector.submit();*/

            window.location = url;

    	});

    };



};
