var djf = djf || {};

$(function () {
	djf.navigation.init();
});

djf.navigation = new function () {
	var self = this,
	menu = $('#nav-container'),
	offCanvas = $('#offcanvas-nav'),
	menuList = $('#navigation-list'),
	menuUl = $('#navigation-list > li'),
	menuUlWithChild = $('#navigation-list > li.has-child'),
	menuTrigger = $('#close-nav, #offcanvas-trigger, .close-overlay'),
	subnavTrigger = $('.subnav-trigger'),
	navTriggerText = $('#nav-trigger-text'),
	navTriggerIcon = $('#nav-trigger-burger'),
	activeItem = $(''),
	languageTrigger = $('#language-picker #trigger');


	this.init = function () {

		self.languagePicker(languageTrigger);

		self.moveTheMenu();

		// Bind the menu-button on init.
		self.triggerMenu(menuTrigger);

		// Bind the submenu triggers on init.
		self.openSubMenu(subnavTrigger);

		// Set min data-height
		self.SetDataHeight(menuUlWithChild);

		// Sticky menu;
		self.stickyMenu(menu)

		// hoverIntentInit  
		self.hoverIntent(menuUl);

		// In Resize event
		$( window ).smartresize(function() {

			self.moveTheTopNav();

			// Apply minheight css
			if($(window).width() > 1024) {
				// Bind HoverIntent actions
				self.hoverIntent(menuUl);
				// Remove menu-open class
				$('html').removeClass("menu-open");
			}
		}).trigger('resize');


		// Check for active class
		if (activeItem.length > 0) {
			/*activeItem.parents('li').addClass('opened').find('ul').show();*/
			activeItem.parents('li').addClass('opened');
		}
	};

/*    this.getAjaxMenu = function (t) {
		var contentUrl = menu.attr('data-url');

		var jqxhr = $.get(contentUrl, function (data) {
			$('#main-menu').html(data);

		});

		jqxhr.done(function() {
			self.init();

			setTimeout(function() {
				self.hoverIntent(menuUl);
			}, 100);
		})

	};*/

	this.moveTheMenu = function() {
		menuList.appendTo('#main-menu');
		menuList.show();
	}

	this.triggerMenu = function (t) {
		t.on('click', function (e) {
			e.preventDefault();
			e.stopPropagation();

			var siteHeight = $('#site').attr('data-height');
			// Toggle body class
			$('html').toggleClass("menu-open");

			if ($('html').hasClass('menu-open')) {
				$('#site').css('min-height', siteHeight + "px");
			} else {
				$('#site').css('min-height', "auto");
			}

		});
	};

	this.SetMaxHeight = function (selector) {

		var activeSubs = selector.find('ul.slideToggle'),
		subUl = selector.find('ul').first(),
		minHeight = 0,
		ulHeights = new Array();


		activeSubs.each(function() {
			var height = $(this).attr('data-height');
			ulHeights.push(height);
		});

		// Get heights value of array
		minHeight = Math.max.apply(Math, ulHeights);


		// Apply minheight css
		if($(window).width() > 1024 && minHeight > 0 ) {
			subUl.attr('style','min-height:'+minHeight+'px;');
		} else {
			subUl.attr('style','min-height:0');
		}
	};

	this.openSubMenu = function (t) {
		t.click(function (e) {
			e.preventDefault();
			e.stopPropagation();


			var $this = $(this),
			$openItem = $('.opened'),
			$topLevel = $this.parents('#navigation-list > li');
			$parentLi = $this.parents('li'),
			$parentOpenItem = $parentLi.filter('.opened');


			$openItem.not($parentOpenItem).removeClass('opened').find('ul').removeClass('slideToggle');
			$this.parent().next().toggleClass('slideToggle');
			$this.closest('li').toggleClass('opened');

			// Set max-height
			self.SetMaxHeight($topLevel);
		});

		// Add opened class to active
		var $menu = $('#sidebar-menu');
		$menu.find('.active').parents('li').addClass('opened').children('ul').addClass('slideToggle');
	};

	this.SetDataHeight = function (t) {
		t.each(function() {
			var subUl = $(this).find('ul');

			// Fake show the menu to measure height
			$(this).addClass('hover');
			subUl.addClass('slideToggle');

			subUl.each(function() {
				var height = $(this).height() + 40;
				$(this).attr('data-height', height);
			});

			// Set min-height for off-canvas
			$('#site').attr('data-height', menuList.height());

			// Remove the fake show of menu
			$(this).removeClass('hover');
			subUl.removeClass('slideToggle');

		});
	}
	this.moveTheTopNav = function() {
		var $listItems = $('#top-nav li'),
		$movedItems = $('#navigation-list li.has-moved')

		if ($(window).width() < 1024) {
			$listItems.addClass('has-moved');
			$listItems.appendTo('#navigation-list');

		}else{
			$movedItems.appendTo('#top-nav ul');
		}

	};

	this.hoverIntent = function (t) {
		t.hoverIntent({    
			sensitivity: 3, // number = sensitivity threshold (must be 1 or higher)    
			interval: 100, // number = milliseconds for onMouseOver polling interval    
			timeout: 400, // number = milliseconds delay before onMouseOut    
			over:function(){
				$(this).addClass('hover');
			},
			out: function(){
				$(this).removeClass('hover');
			}
		});
	};

	this.stickyMenu = function (selector) {
		var pxToTop = $('#top-container').outerHeight();

		$(document).scroll(function() {
			if( $(this).scrollTop() >= pxToTop ) {
				$('body').addClass('fixed-menu');
			} else {
				$('body').removeClass('fixed-menu');
			}
		});
	}

	this.languagePicker = function(selector) {
		selector.click(function() {
			$('#language-list').fadeToggle('100');
		});
	}

};