var djf = djf || {};

$(function () {
    djf.signup.init();
});

djf.signup = new function () {

    this.init = function () {
        var birthDate = document.getElementById('birth-date');
        if (birthDate != null) {
            new Pikaday({
                field: document.getElementById('birth-date'),
                format: 'YYYY-MM-DD',
                yearRange: [1915, 2015],
                i18n: {
                    previousMonth: 'Forrige m&aring;ned',
                    nextMonth: 'N�ste m&aring;ned',
                    months: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
                    weekdays: ['S&oslash;ndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'L&oslash;rdag'],
                    weekdaysShort: ['S&oslash;n', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'L&oslash;r']
                }
            });
        }

        $('#thank-you-modal').modal('show');
    }

};